<template>
  <div>
    <form>

      <div v-if="is_mobile">
        <BankIDOpenAppComponent 
          :intent="intent"
          :type="type"
          :return_url="window_url"
        />
      </div>
      <div v-else>
        <BankIDQrCodeComponent 
          :intent="intent"
          :type="type"
          @authenticated="authenticated"
          @failed="failed"
        />
      </div>
      
      <div v-if="show_user_not_found" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_USER')}}</p><p>{{$t('ACCOUNT.NO_USER_INFO')}}</p></div>
      </div>
      
      <div v-if="show_no_permissions" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
      </div>
      
    </form>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { is_mobile } from '@/core/services/utils';

import BankIDOpenAppComponent from '@/view/components/bankid/BankIDOpenAppComponent.vue';
import BankIDQrCodeComponent from '@/view/components/bankid/BankIDQrCodeComponent.vue';

export default {
  name: 'NativeBankIDComponent',
  props: ['redirect','intent','type'],
  components: {
    BankIDOpenAppComponent,
    BankIDQrCodeComponent
  },
  data() {
    return {
      show_user_not_found: false,
      show_no_permissions: false,
    };
  },

  computed: {

    window_url() {
      return `${window.location.origin}${this.$route.fullPath}`;
    },

    is_mobile() {
      return is_mobile();
    },

    ...mapState({
      errors: state => state.auth.errors
    })
  },
  
  async mounted() {

    this.debug = this.$route.query.debug !== undefined ? true : false;

    if (this.debug) {
      console.log('DEBUG ENABLED!');
    }
  },

  methods: {
    /**
     * @param data {
        expires_at: 1762206098,
        member: { member_id: 73, import_member_id: null, created_at: "2024-10-31 22:22:06",…},
        refresh_token: "eyJhbG...",
        status: "authenticated",
        token: "eyJhb..."
      }
     */
    authenticated(data) {
      this.$emit('authenticated', data);
    },

    failed() {
      this.$emit('failed');
    }
  },
  
};
</script>



<style lang="scss" scoped>

#qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>