<template>
  <div>

    <div class="container" v-if="autostarttoken">

      <img :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" alt="Logo" class="logo">
      
      <memlist-button
        :size="'large'"
        :title="$t('BANKID.OPEN_BANKID')"
        @clicked="open_bankid_clicked"
      />
    </div>
    
  </div>

</template>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { getDeviceId } from '@/core/services/deviceId';
import { mapState } from 'vuex';


import axios from 'axios';

export default {
  name: 'BankIDQrCodeComponent',
  components: {
    
  },
  props: [ 'return_url', 'intent', 'type' ],
  data() {
    return {
      autostarttoken: null
    };
  },
  
  async mounted() {
    this.deviceId = getDeviceId();

    this.fetch_bankid_app_auth();
  },

  methods: {

    open_bankid_clicked() {
      location.href = this.bankid_url;
    },

    async fetch_bankid_app_auth() {
      try {
        const res = await axios.post(`/native/bankid/${this.type}`, { 
          deviceId: this.deviceId, 
          userAgent: window.navigator.userAgent, 
          returnUrl: this.return_url,
          intent: this.intent
        });

        if (res.status === 200) {
          this.autostarttoken = res.data.autoStartToken;
          
          if (!this.autostarttoken) {
            console.error('invalid autostarttoken');
          }
        }
      }
      catch (err) {
        console.error('fetch_bankid_app_auth', err);
      }
    },
  },
  computed: {
    bankid_url() {
      return `https://app.bankid.com/?autostarttoken=${this.autostarttoken}`;
    },

    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style lang="css" scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid #dedede;

  max-width: 680px;

  border-radius: 12px;
}

.logo {
  width: 128px;
  height: auto;
}

.text {
  font-size: 14px;
  margin: 2px 0;
}

.bottom-image {
  width: 480px;
  height: auto;
}

</style>